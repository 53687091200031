import {useCurrentSlug} from './useCurrentSlug';
import {useEnhanced} from './useEnhanced';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';

export function useIsCoverageHighlighted() {
  const {setId} = useCurrentSlug();
  const {samplesAvailable, newHeaderAvailable} = useEnhanced(setId);
  const isHighlightedHeaderAvailable =
    useFlag(FLAGS.NEW_HEADER) && newHeaderAvailable;

  return isHighlightedHeaderAvailable && !samplesAvailable;
}
