import {Card, Text, Link} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useCallback, useState} from 'react';
import AnimateHeight, {type Height} from 'react-animate-height';
import {
  CEVENT_LEARN_MORE_BTN_CLICKED,
  DlvLearnMoreBtnClicked,
} from '@/constants';
import {NEW_VERSION_DS} from '@/constants/flags';
import {useFlag} from '@/context';
import {customEvent, proccessSectionId} from '@/utils/gtm';

interface IDescriptionProps {
  content?: string | React.ReactNode;
  parentId?: string;
  closedText?: string;
  openedText?: string;
}

const Description: FC<IDescriptionProps> = ({
  content,
  parentId,
  closedText = 'Learn More',
  openedText = 'Hide',
}) => {
  const [height, setHeight] = useState<Height>(0);

  const renderContent = useCallback(() => {
    if (typeof content === 'string') {
      return <Text>{content}</Text>;
    }
    return content;
  }, [content]);

  const handleClick = useCallback(() => {
    setHeight(height === 0 ? 'auto' : 0);
    if (height === 0) {
      customEvent<DlvLearnMoreBtnClicked>(CEVENT_LEARN_MORE_BTN_CLICKED, {
        resourceSection: proccessSectionId(parentId ?? ''),
      });
    }
  }, [height, parentId]);

  return (
    <div className='flex flex-col space-y-2'>
      <Link
        className='self-end'
        textClassName='text-neutral-primary'
        onClick={handleClick}
        label={height !== 0 ? openedText : closedText}
      />
      <AnimateHeight duration={300} height={height} className='pl-4'>
        {renderContent()}
      </AnimateHeight>
    </div>
  );
};

export interface ISubSectionProps {
  children: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  tags?: string | React.ReactNode;
  id?: string;
  button?: React.ReactNode;
  className?: ClassValue;
  titleContainerClassName?: ClassValue;
  redirectTo?: string;
  shownRedirectBtn?: boolean;
  redirectBtnText?: string;
}

const SubSection: FC<ISubSectionProps> = ({
  title,
  children,
  description,
  tags,
  id,
  button,
  className,
  titleContainerClassName,
  redirectTo,
  redirectBtnText,
  shownRedirectBtn,
}) => {
  const newVersionDs = useFlag(NEW_VERSION_DS);

  return (
    <div className={clsx('space-y-5', className)} id={id} data-testid={id}>
      {!title && !button ? null : (
        <div
          className={clsx(
            'flex flex-col justify-between space-y-2 md:flex-row md:items-center',
            titleContainerClassName,
          )}>
          {title ? (
            <Text as='body-md' weight='extrabold' tag='h2'>
              {title}
            </Text>
          ) : null}
          {!!button && button}
        </div>
      )}
      {!!tags && tags}
      <Card className='shadow-none'>{children}</Card>
      {shownRedirectBtn && redirectTo ? (
        <div className='flex flex-col space-y-2'>
          <Link
            href={redirectTo}
            type={newVersionDs ? 'underline' : undefined}
            className='self-end'
            textClassName={clsx({'font-semibold': newVersionDs})}
            linkSize={newVersionDs ? 'lg' : undefined}
            label={redirectBtnText ?? ''}
          />
        </div>
      ) : null}
      {description ? <Description parentId={id} content={description} /> : null}
    </div>
  );
};

export default SubSection;
